@import './components/mixins';
@import './components/custom-react-sidebar';
@import './components/emoji-styles';
@import './components/toggle-switch-px';
@import './components/recurr-options';
@import './components/dashboard-styles';
@import './components/members-list';
@import './components/chat-settings';
@import './components/chat-sett-toggler';
@import './components/case-form_depr';
@import './components/rc-tree-select';
@import './components/uploadbox';
@import './components/upload-media-spinner';
@import './components/data-list';
@import './components/rc-mention';

:root {
	--primary-green: #00d0bd;
}

//Right to left layout styles
html[dir="rtl"] {
  .rct-scroll > div {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
  }

  .rct-scroll > div::-webkit-scrollbar {
	display: none;
  }

  .react-tel-input .selected-flag {
	.arrow {
	  left: 8px;
	}

	.flag {
	  left: 25%;
	}
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: rgba(211, 211, 211, 0.1);
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	visibility: hidden;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.2);
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.app-mode__dark {
	::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.3);
		// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}
}

html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.slide-pane__overlay.overlay-after-open {
	z-index: 1400;
}



mark.highlighted {
	background-color: transparent;
	color: red;
}

.filter-date-range-picker {
	width: 100%;

	.rdrMonths,
	.rdrMonth {
		width: 100% !important;
	}

	.rdrDayToday .rdrDayNumber span::after {
		background-color: #00d0bd;
	}

	::-webkit-scrollbar {
		width: 2px;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	::-webkit-scrollbar-thumb {
		background: #888;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.filter-date-range-picker__dark {
	.rdrDateDisplayWrapper {
		background-color: #2b2b2b;

		.rdrDateDisplayItem {
			background: inherit;
		}
	}

	.rdrDayNumber span {
		color: #fff;
	}

	.rdrMonthAndYearWrapper {
		.rdrNextPrevButton {
			background: inherit;
		}

		.rdrNextButton i {
			border-color: transparent transparent transparent #fff;
		}

		.rdrPprevButton i {
			border-color: transparent #fff transparent transparent;
		}

		.rdrMonthAndYearPickers select {
			color: #fff;
			background: #2d2d2d;
		}
	}
}

.phone-input-dark {
	.form-control {
		background-color: #1e1e1e !important;
		border-color: rgba(255, 255, 255, 0.3) !important;
	}

	.selected-flag, .country-list, .country-list .search, .country-list .search .search-box {
		background-color: #1e1e1e !important;
		border-color: rgba(255, 255, 255, 0.3) !important;
	}

	.country-list .country.highlight {
		background: rgba(255, 255, 255, 0.1) !important;
	}

	.country-list .country:hover > .country-name {
		color: grey;
	}

	.country-list .country-name {
		color: #fff !important
	}
}

.react-select-paginate {
	[class*='-indicatorContainer'] {
		width: 40px;
		height: 40px;
		justify-content: center;
		align-items: center;
	}

	[class*='-menu'] {
		z-index: 1000;
	}
}

.dark {
	.react-select-dropdown {
		[class*='css-'] {
			[class*='option']:hover, 
			[class*='is-selected'] {
				background-color: rgba(0, 208, 189, .2);
			}

			[class*='multiValue'], 
			[class*='multiValue'] div {
				background-color: #888;
				border-radius: 0;				
			}

			[class*='multiValue'] div:last-child:hover, 
			[class*='multiValue'] div:last-child:hover > svg  {
				background-color: #FFBDAD;
    			color: #DE350B;
			}
			background-color: #1e1e1e;
			color: #fff;
			border-radius: 10px;
		}
		[class*='-container'] {
			border-radius: 10px;
		}
	}

	/* WebKit, Edge */
	::-webkit-input-placeholder {
		color: #fff;
	}
	/* Firefox 4-18 */
	:-moz-placeholder {
		color: #fff;
		opacity: 1;
	}
	/* Firefox 19+ */
	::-moz-placeholder {
		color: #fff;
		opacity: 1;
	}
	/* IE 10-11 */
	:-ms-input-placeholder {
		color: #fff;
	}
	/* Edge */
	::-ms-input-placeholder {
		color: #fff;
	}
	/* MODERN BROWSER */
	::placeholder {
		color: #fff;
	}

	//.rdrCalendarWrapper {
	//    color: white!important;
	//}
	//
	//.rdrDateDisplay {
	//    background-color: #1e1e1e!important;
	//}
}

.pro-sidebar {
	z-index: 1101;
}

.welcome__left {
}

.rtl-dialog button {
	margin: 0px .2rem;
}

.custom-date-picker {
	.MuiOutlinedInput-notchedOutline {
		border-color: #e0e0e0;
		border-radius: 8px;
	}
	.MuiOutlinedInput-root:hover,
	.MuiOutlinedInput-notchedOutline:hover,
	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: #00d0bd !important;
	}

	.MuiOutlinedInput-inputMarginDense {
		padding-top: 13.5px;
		padding-bottom: 13.5px;
	}
}

//.react-resizable {
//    position: absolute;
//    top: 0;
//    right: 0;
//    height: 100%;
//}
//.react-resizable-handle {
//    position: absolute;
//    width: 20px;
//    height: 20px;
//    bottom: 0;
//    right: 0;
//    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
//    background-position: bottom right;
//    padding: 0 3px 3px 0;
//    background-repeat: no-repeat;
//    background-origin: content-box;
//    box-sizing: border-box;
//    cursor: se-resize;
//}

.add-chat-member__list {
	.member-list-item .MuiTouchRipple-root:last-child {
		padding-bottom: 6.5rem
	}
}

.msg-service-text::before {
	content: '';
	position: absolute;
	border-bottom: 1px solid #707c97;
	top: 50%;
	left: 0;
}

//.video-msg video {
//    max-height: 100%;
//    height: 100%!important;
//}

.scrollable-feed {
	//overflow-y: unset!important;
	height: 100% !important;
}
.chat__body div[id]:first-child {
	padding-top: .5rem;
}

.indicate {
	animation: flickr 1000ms 2 forwards;
}

@keyframes flickr {
	0% {
		background-color: transparent;
	}

	50% {
		background-color: #00d0bd;
	}

	100% {
		background-color: transparent;
	}
}

//Recording animatin
/*======= All App Effect Is Mentioned Here ========*/
.rct-notify {
	animation: notify 1.5s infinite linear;
	border-radius: 100%;
}
@keyframes notify {
	0% {
		box-shadow: 0 0 0 0 rgba(179, 182, 183, 0.96);
	}
	70% {
		box-shadow: 0 0 0 12px rgba(179, 182, 183, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(179, 182, 183, 0);
	}
}

.group-sidebar__search-input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 10px;
}
.group-sidebar__search-input::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 10px;
}
.group-sidebar__search-input:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 10px;
}
.group-sidebar__search-input:-moz-placeholder {
	/* Firefox 18- */
	font-size: 10px;
}

.chat-dropzone {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	background-color: #fff;
	font-size: 20px;
	z-index: 10011;
	.reject &,
	.accept & {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
	}
}

.chat.accept {
	.message-like-button,
	.pinned-case {
		z-index: 0 !important;
	}
}

.chat-panel__resize-handler {
	cursor: col-resize;
}
@media (min-width: 1024px) {
	.chat-panel {
		min-width: 36rem;
		max-width: calc(100% - 285px);
	}
}

.custom-kennekt-thead::after {
	content: '';
	display: block;
	width: 100%;
	height: 0;
	background-color: transparent;
}

.prime-tabs:not(.prime-tabs_no-bottom-extend) {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		height: 10px;
		background-color: #fafafa;
		width: 100%;
		left: 0;
		right: 0;
		top: 100%;
	}
}

.profile__header-status-icon::before {
	content: '';
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}

.react-tel-input .flag-dropdown {
	border: none 0 !important;
}
.dark-mode-react-tel-input .flag-dropdown {
	background-color: #1e1e1e !important;
}

.pac-container {
	z-index: 10000 !important;
}

.height-preserving-container:empty {
	min-height: calc(var(--child-height));
	box-sizing: border-box;
}

/*
group-hover:pointer-events-auto
			group-hover:opacity-100 group-hover:translate-y-2
*/

.filter-templates-dropdown {
	pointer-events: none;
	//opacity: 1;
	//z-index: 1000;
	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		height: 10px;
		top: -10px;
		//background-color: yellow;
	}
}

.filter-templates-button:hover .filter-templates-dropdown {
	pointer-events: auto;
	opacity: 1;
	transform: translateY(8px);
	z-index: 1000;
}

.vars-column {
}

.vars-column__title {
	margin-bottom: 20px;
	font-weight: bold;
}

.vars-column__item {
	cursor: pointer;
	transition: all 0.3s;
	&:hover {
		color: rgba(#000, 0.5);
	}
}

// Quill Ediotr
.quill {
	overflow: hidden;
}
.ql-container {
	height: 300px !important;
}
.ql-container.ql-snow,
.ql-toolbar.ql-snow {
	border-color: #ced4da;
}

.no-cases-found > div > div {
	overflow: hidden !important;
}
.dark .tel-input-profile .form-control {
	background-color: #1e1e1e !important;
	color: #ffffff;
}

.dark .react-tel-input .selected-flag:hover {
	background-color: #2b2b2b;
}

//.cases-page-wrapper,
//.statuses-wrapper,
.case-statuses-scroll-container {
	overscroll-behavior: contain;
}

.cases-page-wrapper {
	overflow-x: scroll;

	::-webkit-scrollbar {
		width: 5px;
		height: 8px;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		visibility: hidden;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.2);
		// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}
}
.cases-page-wrapper-dark {
	::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.2);
		// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}
}
//.chat-header__identity-text,
//.chat-header__identity-text div:first-child,
.chat-header__content {
	max-width: calc(100% - 318px);
}

.dark_quill .ql-toolbar .ql-picker {
	color: #fff;
}

.dark_quill .ql-toolbar .ql-stroke {
	fill: none;
	stroke: #fff;
}

.dark_quill .ql-toolbar .ql-fill {
	fill: #fff;
	stroke: none;
}
//.status__inner > div > div:not(:first-child) {
//	display: none !important;
//}
.status__inner {
	::-webkit-scrollbar {
		width: 5px;
		height: 1px;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		visibility: hidden;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.2);
		// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}
}
.status__inner-dark {
	::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.2);
		// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}
}

@media (max-width: 767px) {
	.chat-header__content {
		max-width: 200px;
	}
}

.msg-wrp:hover .message-like-button,
.msg-wrp:hover .translate-button {
	opacity: 1;
}
.chat_messages_scroll_track {
	top: 2px;
	bottom: 2px;
	right: 2px;
	border-radius: 3px;
	background: rgba(211, 211, 211, 0.1);
	display: block !important;

	.chat_messages_scroll_thumb {
		position: relative;
		width: 100% !important;
		cursor: pointer;
		border-radius: inherit;
		background-color: rgba(0, 0, 0, 0.2); // changed the color
	}
	.chat_messages_scroll_thumb-dark {
		background-color: rgba(255, 255, 255, 0.2) !important;
	}
}

.view {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: scroll;
	margin-right: -15px;
	margin-bottom: -17px !important; // changed from -15px  (default) to -17px (native scrollbar had a portion visible)
}

.lb-custom-wrapper .lb-icon-arrow {
	display: none;
}

.lb-icon-arrow {
	position: absolute;
	top: 50%;
	padding: 12px 12px !important;
	background-size: 100% !important;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVR4nO3dwU3DQBRF0ZQEtVABWdEFW3pGcFGkREhQgB/knN149aWr8Sxs2acTAAAAAAAA/0r1cPQMXFXP1Uf1drvG8TFuRDkwxvlHjK7rx8OGulf93hkXn9XL0bPdncTYkRg7EmNHYuxIjB2JsSMxdiTGjsTYkRg7EmNHYuxIjB2JsSMxdiTGjsTYkRg7EmNHYuxIjB1iDBFjiBhDxBgixhAx9mNc1uejZ7s71VP17sXnEYIMcssa5FAfJMogUQaJMkiUQaIMygOqPYmyJ1H2JMqeRNmTKHsSZU+i7EmUPYmyJ1H2JMqeRNmTKHsSZU+i7EmUPYmyJ1H2JMqeRNmTKHvyMf4/sVNej57p7vUdRYwV+eURAAAAAADA6Uhfe323E5njGc8AAAAASUVORK5CYII=") !important;
}


.lb-icon-arrow.prev {
	left: 5%;
	transform: scale(2);

}

.lb-icon-arrow.next {
	right: 5%;
	transform: rotateY(180deg) scale(2) !important;
}

#notistack-snackbar {
	overflow: hidden;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	position: relative;
	padding-left: 35px;

	svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
	}
}

.mobile-prompt-modal .MuiDialogContent-root {
	padding-top: 0 !important;
}

.mobile-prompt-modal .MuiDialog-paper {
	overflow: hidden !important;
	border-radius: 8px !important;
}

.list-item-wrapper:nth-of-type(2) .message-sender-name,
.list-item-wrapper:nth-of-type(2) .message-avatar {
	opacity: 1!important;
	transition: opacity .2s ease;
}

.schedule-custom-date__content {
  .rdrCalendarWrapper {
    width: 100%;

    .rdrMonth {
      width: 100%;
    }
  }

  .dark-theme {
    .rdrCalendarWrapper {
      background: transparent;
    }

    .rdrMonthAndYearPickers select {
      color: #eee;
	  background: #555;
    }

    .rdrDayDisabled {
      background-color: #555555;
    }

    .rdrDayNumber span {
      color: #fff;
    }

    .rdrDayDisabled .rdrDayNumber span {
      color: #aeb9bf;
    }
  }
}

.jvectormap-container {
  svg > g {
    text {
      transform: translateX(-4.45%);
    }
	}
}
.kennekt-input[type="password"] {
  &::-ms-reveal, &::-ms-clear  {
    display: none;
  }
}

.business-not-allowed-select {
	td {
		pointer-events: none;
		opacity: 50%;

		&:nth-last-child(-n+2) {
			pointer-events: auto;
			opacity: 100%;
		}
	}


}

.invitation-message {
	& > div {
		border: none !important;
	}

	div, p {
		background-color: rgba(14, 14, 14, 0.1) !important;
	}

	b, a {
		color: #00d0bd !important;
		background-color: rgba(14, 14, 14, 0.1) !important;
		border: none !important;
	}

	span, p {
		color: #fff !important;

		
	}
}
