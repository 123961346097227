.upload-media-spinner {
  z-index: 200;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;

  .MuiCircularProgress-root {
    height: 100% !important;
    width: 100% !important;
  }
}

.upload-cancel-icon {
  width: 38px;
  height: 38px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  z-index: 11;

  &::before,
  &::after {
    content: "";
    height: 1px;
    width: 15px;
    background-color: #00D0BD;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg) !important;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg) !important;
  }

  &:hover {
    &::before,
    &::after {
      width: 20px;
    }
  }
}
