@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind layer;

@layer base {
    button:focus {
        outline: none;
    }
}

@layer components {
    .flex-centered {
        @apply flex items-center justify-center;
    }

    .flex-center-y {
        @apply flex  items-center;
    }

    .flex-center-end {
        @apply flex items-center justify-end;
    }

    .flex-center-x {
        @apply flex  justify-center;
    }

    .flex-center-between {
        @apply items-center justify-between flex;
    }

    .flex-center-center {
        @apply flex items-center justify-center;
    }

    .position-centered {
        @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
    }

    .case-type-select-container .case-type-select__control {
        @apply dark:border-white/20 dark:bg-kdark;
    }

    .case-type-select-container .case-type-select__single-value {
        @apply dark:text-white;
    }

    .my-react-select-container .my-react-select-prefix__control {
        @apply dark:bg-kdark;
    }

    .my-react-select-container .my-react-select-prefix__single-value, .my-react-select-container .my-react-select-prefix__option {
        @apply dark:text-white;
    }

    .my-react-select-container .my-react-select-prefix__menu {
        @apply dark:bg-kdark;
    }

    .my-react-select-container .my-react-select-prefix__option {
        @apply dark:bg-kdark;
    }

    .my-react-select-container .my-react-select-prefix__option--is-focused {
        @apply dark:bg-primary-200;
    }

    .my-react-select-container .my-react-select-prefix__option--is-selected {
        @apply dark:bg-primary-dark;
    }

    .cc-recurring-timepicker .MuiOutlinedInput-root {
        @apply rounded-10;
    }

    .cc-recurring-timepicker .MuiInputBase-input {
        @apply font-light font-poppins whitespace-pre-line;
    }

    .cc-recurring-timepicker .MuiOutlinedInput-inputMarginDense {
        @apply py-3.5;
    }
    
    .dark-mode-styled-table thead th {
        @apply dark:bg-kdark-lighter;
    }

    .dark-mode-styled-table {
        @apply min-w-[1250px] 2xl:min-w-full;
    }

    .switch-toggle-custom {
        @apply dark:!bg-kdark-lighter;
    }

    .switch-toggle-custom label {
        @apply dark:!text-white;
    }

    .prime-tabs {
        @apply dark:after:!bg-kdark-lighter;
    }

    .prime-tabs-clients {
        @apply overflow-hidden;
    }

    .prime-tabs-clients .MuiTabs-root {
        @apply min-w-[800px] xl:!min-w-full !overflow-x-auto;
    }

    .cc-recurring-timepicker .MuiOutlinedInput-notchedOutline {
        @apply border-kgrey-inputBd hover:border-primary;
    }

    .cc-recurring-timepicker .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
        @apply border-primary transition;
    }

    .news-view-row {
        @apply flex items-center mb-2.5;
    }

    .checkout-counter {
        @apply w-3.5 h-3.5 rounded-full bg-kgrey text-white transition hover:bg-primary-dark cursor-pointer flex-centered text-xs;
    }

    .snackbar-wrapper {
        @apply max-w-screen-md;
    }

    /*copied from branch related to business transfer*/
    .my-react-select-container .my-react-select-prefix__option:first-child {
        @apply dark:bg-transparent hover:dark:text-kdark hover:dark:bg-white;
    }

    .audio-msg::-webkit-media-controls-panel {
        @apply dark:bg-primary-dark-50 bg-primary-200 pr-6;
    }
}
