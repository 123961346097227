.members-list {
}

.members-list__filter_inner {
  @include respond(phone) {
    flex-wrap: wrap;
  }
}

.members-list__settings {
  padding: 10px;
  display: flex;
  align-items: center;
}

.members-list__search {
  flex-grow: 1;
}

.members-list .rct-scroll > div:first-child {
  width: 100%;
}
