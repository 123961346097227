.chat-settings {
  width: 290px;
  padding: 12px;
  border-radius: 16px !important;
  overflow: hidden;
}
.uni-modal_chat-settings-new {
}

.chat-settings__header {
  background-color: #464d69;
  padding: 15px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 8px 16px rgba(#314284, 0.5);
  margin-bottom: 16px;
}

.chat-settings__header-img {
  width: 58px;
  height: 58px;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 24px;

  .MuiAvatar-square {
    width: 100%;
    height: 100%;
  }
}

.chat-settings__name {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 4px;
}

.chat-settings__members-count {
  font-size: 12px;
}

.chat-settings__header-edit {
  margin-left: auto;
  align-self: flex-start;
  .edit-icon {
    transition: ease 0.3s;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.chat-settings__i:not(:last-child) {
  margin-bottom: 10px;
}
