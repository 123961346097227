$mentionsPrefixCls: 'rc-mentions';

.rc-mentions {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;

// ================= Input Area =================
> textarea, &-measure {
  font-size: inherit;
  font-size-adjust: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-weight: inherit;
  font-family: inherit;
  background-color: transparent;

  padding: 0;
  margin: 0;
  line-height: inherit;
  vertical-align: top;
  overflow: inherit;
  word-break: inherit;
  white-space: inherit;
  word-wrap: break-word;
  overflow-x: initial;
  overflow-y: auto;
  text-align: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  tab-size: inherit;
  direction: inherit;
}

> textarea {
  border: none;
  width: 100%;
}

&-measure {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  // color: rgba(255, 0, 0, 0.3);
  color: transparent;
  z-index: -1;
}

// ================== Dropdown ==================
&-dropdown {
  position: absolute;

  &-menu {
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
      cursor: pointer;
    }
  }
}
}

// Customize style
.rc-mentions {

  &-dropdown {
    box-shadow: 1px 1px 10px 0 rgba(0,0,0,.15);
    border-radius: 5px;
    padding: 5px;
    background: #FFF;
    max-height: 300px;
    overflow-y:auto;

    &-menu {
      &-item {
        padding: 4px 8px;

        &-active {
          background: rgba(0, 208, 189, .2);
        }

        &-disabled {
          opacity: 0.5;
        }
      }
    }
  }
}


.dark .rc-mentions-dropdown {
  background-color: #181818;
  box-shadow: 1px 1px 10px 0 rgba(255,255,255,.15);
}

.dynamic-option {
  display: flex;
  border-radius: 5px;
  transition: all .3s;

  img {
    max-width: 100%;
    max-height: 100%;
    transition: all .3s;
  }

}

.rc-textarea:focus {
  outline:none;
}

.rc-textarea::selection {
background-color: rgba(0, 208, 189, .2);
}
