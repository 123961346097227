
$mobileonly: "(max-width: 767px)";
// Max width where tablet is still affect finishes
$mobtabonly: "(max-width: 1023px)";
// $mobtabonly: '(max-width: 1170px)';
$tabonly: "(min-width:768px) and (max-width: 1023px)";
// $porttab: '(min-width:768px) and (max-width: 800px)';
// $landtab: '(min-width:1024px) and (max-width: 1249px)';
$tabdeskonly: "(min-width: 768px)";
//where desktop begins
// $deskonly: '(min-width: 1250px) and (max-width:1299px)';
$deskonly: "(min-width: 1024px)";


@mixin respond($breakpoint) {
  @if $breakpoint == tablet {
    // @media (max-width: 1200px) {
    @media #{$mobtabonly} {
      @content;
    }
  }

  @if $breakpoint == phone {
    @media #{$mobileonly} {
      @content;
    }
  }

  @if $breakpoint == tabonly {
    @media #{$tabonly} {
      @content;
    }
  }

  @if $breakpoint == porttab {
    @media #{$porttab} {
      @content;
    }
  }

  @if $breakpoint == landtab {
    @media #{$landtab} {
      @content;
    }
  }

  @if $breakpoint == tabdesk {
    @media #{$tabdeskonly} {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media #{$deskonly} {
      @content;
    }
  }
  @if $breakpoint == alldesktop {
    @media #{$alldeskonly} {
      @content;
    }
  }
  @if $breakpoint == xldesktop {
    @media #{$xldeskonly} {
      @content;
    }
  }
}
