.chat-bubble__selected-emojies {
  //position: absolute;
  max-width: 100%;
  margin: -10px;
  padding: 10px;
  border-radius: 40px;
  .PriorityNav_Root {
    //width: 100%;
    //max-width: 400px;
    max-width: 100%;

    @include respond(phone) {
      max-width: 180px;
    }
  }
  .overflow-btn {
    position: absolute;

    top: 10px;
    z-index: 1;
    width: 42px;
    height: 33px;
    padding: 0;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(#000, .2);
    cursor: pointer;
    border-radius: 15px;
    transition: all .3s;
    &:hover {
      background-color: #fff;
      box-shadow: none;
      //transform: translateY(-3px);
    }
  }
}

.chat-bubble__selected-emojies .overflow-btn {
  left:-1px;
  .even & {
    right: -1px;
    left: auto;
  }


}

.chat-emoji {
  margin-right: 5px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(#000, 0.2);
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:not(:first-child) {
    margin-left: 5px;
  }
  &:first-child {
    margin-left: -10px;
  }

  &:hover {
    box-shadow: none;
    transform: translateY(-3px);
  }
}

.chat-emoji__count {
  margin-left: 5px;
  font-size: 13px;
  font-weight: bold;
  color: #000;
}

.chat-emoji__unicode {
  line-height: 1;
}

/* emoji mart*/
.emoji-mart {
  //margin-top: 20px;
}

.chat-bubble__react-emoji-more {
  .MuiIconButton-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.chat-bubble__react-emoji-custom-dark {
  .emoji-mart-scroll .emoji-mart-category {
    ul li button::before {
      background-color: #2b2b2b !important;
    }
  }
}

.chat-bubble__react-emoji-custom {
  position: relative;
  z-index: 1;
  left: 5px;
  top: 3px;
  .emoji-mart {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .emoji-mart-scroll  {
    padding: 0;
  }



  & > .emoji-mart {
    width: 255px !important;
    //z-index: 999;
  }
  .emoji-mart .emoji-mart-anchors {
    display: none;
  }
  .emoji-mart .emoji-mart-search {
    display: none;
  }
  .emoji-mart .emoji-mart-bar {
    display: none;
  }
  .emoji-mart .emoji-mart-scroll {
    overflow: auto;
    height: auto;
  }
  .emoji-mart .emoji-mart-category-label {
    display: none;
  }
}

.odd .chat-bubble__react-emoji-wrp {
  right: 0;
}



.chat-bubble__react-emoji-more {
  position: relative;
  z-index: 2;
}

.chat-bubble__react-emoji {
  position: relative;
  z-index: 1;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
}


.chat-bubble__react-emoji-wrp {
  position: absolute;
  //z-index: 21;
  top: -35px;
  @include respond(phone) {
    right: -70px;
  }

  .even & {
    left: 0;
  }
}
//countable-emojies
.countable-emojies {
  .PriorityNav_Main {
    //display: flex;

    div {
      vertical-align: middle;
    }
  }

  .PriorityNav_Button {
    height: 29px;

    background-color: #fff;
    border-radius: 15px;
    box-shadow: 2px 2px 5px rgba(#000, 0.2);
    padding: 5px 15px;
    svg path {
      fill: #999;
    }
  }
  .countable-emojies__more-list {
    right: 0;
  }
}

.countable-emojies_mine {
  .PriorityNav_Button {
    float: left;
  }

  .countable-emojies__more-list {
    left: 0;
    right: auto;
  }
}

.countable-emojies__more-list {
  position: absolute;
  top: calc(100% + 5px);
  z-index: 12;
  background-color: #fff;
  border-radius: 5px;

  .chat-emoji {
    margin: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .chat-emoji:not(:last-child) {
    //margin-bottom: 5px;
  }
}

.countable-emojies {
}

.countable-emojies_mine {
}

.file-msg-like {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    transform: scale(1.1);
  }

  &_location {
    bottom: 40px;
    z-index: 1;
  }

  &_audio {
    bottom: 25px;
    right: 13px;
  }

  &_file {
    bottom: 20px
  }

  &_video {
    bottom: -27px;
  }
  &_video_edit {
    bottom:3px;
    right: 26px;
  }
}

.chat-bubble-emoji-count-row {
  position: absolute;
  top: calc(100% + 5px);
  .chat-bubble__selected-emojies {
    margin-left: 0!important;
  }

  right: 0
}

.even .chat-bubble-emoji-count-row {
  left: 0;
  right: auto;
}

.has-reactions {
  margin-bottom: 35px;
}

.chat-bubble__selected-emojies.overflowed {
  overflow: hidden;
  position: relative;
  &::after {
    content: '';
    //text-align: right;
    //font-weight: bold;
    //font-size: 20px;
    //line-height: 1.2;
    position: absolute;
    height: 33px;
    //height: 42px;
    width: 36px;
    top: 10px;
    //top: 6px;
    left: 0;
    pointer-events: none;
    //background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 32%,rgba(255,255,255,1) 100%);
    background: #f8f9fa;

  }

  .even & {
    &::after {
      right: 0;
      left: auto;
    }
  }
}

