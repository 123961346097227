.recurr-options {
  max-width: 315px;
}

.recurr-options__rep-input-wr {
  width: 70px;
}

.recurr-options__day {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: lighten(#e0e0e0, 5%);
  color: #999;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  &.active {
    background-color: #00D0BD;
    color: #fff;
  }
}

.recurr-options__radio {
  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
}

.recurr-options__form-radio {
  width: 100px;
}
.recurr-options__form-field {
  flex-grow: 1;
}

.recurr-options__form-field_occur {
  background-color: lighten(#e0e0e0, 5%);
  border-radius: 3px;
  padding: 4px 10px;
}

.recurr-options__occ-input {
  width: 50px;
  input {
    border: 0 none;
    outline: none;
    box-shadow: none;
  }
}

.recurr-options__occ-text {
  margin-left: 10px;
}
