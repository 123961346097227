$sidebar-bg-color: #fafafa !default;
$sidebar-color: #adadad !default;
$sidebar-width: 285px !default;
$sidebar-collapsed-width: 61px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: rgba(#333, 0.05) !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$dark-background-color: #1e1e1e;
@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar-content {
	padding-left: 3px !important;
	padding-right: 3px !important;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
}

.fit-icon-size {
	svg {
		object-fit: contain;
		max-height: 100%;
		max-width: 100%;
	}
}

.collapsed {
	.menu-item__arrow {
		display: none;
	}
}

.dark {
	.pro-sidebar-inner {
		transition: background-color ease 150ms;
		background-color: $dark-background-color;
	}
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
	padding-top: 0;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
	padding-left: 14px !important;
	padding-right: 14px !important;
	font-size: 14px;
	font-weight: 700;
	color: #6a7480;
	transition: all 0.3s;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
	&,
	.pro-icon {
		color: #494c64 !important;
	}
	.pro-arrow {
		border-color: #00d0bd !important;
	}
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
	color: #a5a7b2;
}

.pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
	border-radius: 2px;
	width: 24px;
	height: 24px;
	min-width: 24px;
}

.pro-sidebar
	.pro-menu
	.pro-menu-item.pro-sub-menu
	> .pro-inner-item
	> .pro-arrow-wrapper
	.pro-arrow {
	border-color: #adadad;
	border-width: 0 1.5px 1.5px 0;
	padding: 1.9px;
}

.pro-menu-item.pro-sub-menu.open {
	.pro-icon {
		color: #181818 !important;
	}
	.pro-inner-item {
		background-color: rgba(0, 208, 189, 0.2);
		border-radius: 12px;
		color: #181818 !important;
	}
}

.pro-sidebar.collapsed
	.pro-menu
	> ul
	> .pro-sidebar_submenu
	> .pro-inner-list-item
	> .popper-inner {
	background-color: #2b2b2b !important;
	border: 1px solid #333 !important;
}

.pro-sidebar.collapsed
	.pro-menu
	> ul
	> .pro-menu-item.pro-sub-menu
	> .pro-inner-list-item
	> .popper-inner {
	background-color: #fafafa;
	border: 1px solid #f4f4f4;
}

.pro-sidebar .pro-menu.shaped .pro-sidebar_submenu > .pro-inner-item > .pro-icon-wrapper {
	background-color: rgba(51, 51, 51, 0.582);
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
	color: #a5a7b2;
}

.pro-inner-list-item {
	background-color: transparent !important;
}

.business-settings-link {
	&.active {
		color: #494c64 !important;
		background-color: #f4f4f4;
		padding: 8px;
		border-radius: 10px;
		width: 100%;
	}
}

.pro-sidebar .pro-menu a:before {
	display: none;
}

.react-slidedown.pro-inner-list-item {
	padding-right: 10px;
}
