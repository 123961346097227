.data-list {
}
.data-list__row {
  margin-bottom: 10px;
}

.data-list__prop {
  font-weight: bold;
  margin-right: 5px;
}

.data-list__value {
  .label-color {
    display: none;
  }
}

.data-list__row-select {
  width: 100%;
}

.data-list__rows-wrapper_accented {
  background-color: lighten(#e0e0e0, 5%);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 20px;
}
