html[dir="rtl"] .dashboard-item { 
	td:first-child {
		&::before {
			right: 0;
			border-radius: 0 6px 6px 0
		}
	}
}

.dashboard-item {
	td:first-child {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 8px;
			border-radius: 6px 0 0 6px;
		}
	}

	&.is-high {
		td:first-child {
			&::before {
				background-color: rgba(255, 183, 15, 0.5);
			}
		}
	}
	&.is-low {
		td:first-child {
			&::before {
				background-color: hsla(0, 0%, 50.2%, 0.2);
			}
		}
	}
	&.is-normal {
		td:first-child {
			&::before {
				background-color: rgba(blue, 0.2);
			}
		}
	}
	&.is-critical {
		td:first-child {
			&::before {
				background-color: rgba(255, 55, 57, 0.5);
			}
		}
	}
}
@media (max-width: 1117px) {
	.dashboard-details {
		justify-content: space-around;
	}
}
