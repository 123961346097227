.chat-sett-toggler {
  font-size: 12px;
  padding-left: 13px;
  padding-right: 13px;
}
.chat-sett-toggler:not(.chat-sett-toggler_simple) {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 8px 20px rgba(#274034, 0.1);
  border-radius: 16px;
  font-size: 14px;
}

.chat-settings__title {
}

.chat-sett-toggler:not(.chat-sett-toggler_simple) .chat-sett-toggler__icon {
  background-color: #464d69;
}
.chat-sett-toggler__icon {
  width: 28px;
  height: 28px;
  border-radius: 9px;
}

.chat-sett-toggler__name {
  transition: all 0.3s;
}

.chat-sett-toggler_simple {
  cursor: pointer;
  box-shadow: none;
  font-size: 16px;
  margin-top: 12px;
  font-weight: 500;
  transition: all 0.3s;
  .chat-sett-toggler__icon {
  }

  &:hover {
    color: #00D0BD;
    .chat-sett-toggler__name,
    .chat-icon-delete,
    .chat-icon-exit,
    path {
      transition: all 0.3s;
      fill: #00D0BD !important;
    }
  }
}
