.uploadbox {
}

.uploadbox__display-list {
  flex-wrap: wrap;
}

.uploadbox__display-item {
  padding: 3px;
  position: relative;
  width: 70px;
  height: 70px;
  border: 2px solid #ccc;
  border-radius: 5px;
  // overflow: hidden;
  &:not(:last-child) {
    margin-right: 15px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}

.uploadbox__display-item_placeholder {
  // border-radius: 50%;
}

.uploadbox__display-item span {
  font-size: 10px;
  word-break: break-all;
  text-align: center;
}

.uploadbox__display-item_audio {
  // width: 320px;
  width: 200px;

  audio {
    width: 190px !important;
  }
}

.uploadbox__display-item-remove {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  top: -8px;
  right: -8px;
  border: 1px solid red;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 2px;
    border: 1px solid red;
    width: 15px;
    transition: all 0.3s;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      border-color: rgba(red, 0.5);
    }
  }
}
